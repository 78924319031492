import { auth } from '../../utils/auth';
import { GLOBAL_RESOURCE_BUNDLES } from '../../utils/constants/global-resource-bundles';
import { show404NonTransPage } from '../../utils/helpers/show404NonTransPage';

import { makeServerQueryClient } from '@dx-ui/framework-react-query';
import { REACT_QUERY_CONFIG } from '../../config/react-query';
import type { BrandQuery, OffersCreditCardGroup } from '@dx-ui/queries-dx-guests-ui';

import Markdown from 'markdown-to-jsx';
import { Head } from '../../components/Head/Head';
import { Link } from '@dx-ui/osc-link';
import { useMemo } from 'react';
import { useTranslation, Trans } from 'next-i18next';
import { InternationalCards } from '../../components/CreditCardsV2';
import { useLanguage, useSegments } from '../../hooks';
import {
  useCreditCardOffersQuery,
  useBrandQuery,
  useCmsPolicyQuery,
  serverSideCreditCardOffersQuery,
  serverSideCmsPolicyQuery,
  serverSideBrandQuery,
} from '@dx-ui/queries-dx-guests-ui';
import { getUrlHost } from '../../utils/helpers/urlBuilder';
import cx from 'classnames';
import { serverSideProps } from '../../utils/helpers/serverSideProps';
import { Spinner } from '@dx-ui/osc-spinner';
import { getThemeClassName } from '@dx-ui/framework-theme';
import { generateError } from '@dx-ui/utilities-generate-error';
import { logError, logWarning } from '@dx-ui/framework-logger';
import { CreditCardsEn } from '../../views/credit-cards/credit-cards-en';
import { CreditCardsJa } from '../../views/credit-cards/credit-cards-ja';

type FullWidthImageData = NonNullable<NonNullable<BrandQuery['brand']>['page']>['fullWidthImage'];

const CreditCards: NextPage<typeof getServerSideProps> = ({ configRules }) => {
  const [t] = useTranslation('credit-cards');
  const { t: tJaCards } = useTranslation('credit-cards-ja');
  const language = useLanguage();
  const segments = useSegments();

  const {
    data: creditCardOffersData,
    isLoading,
    error: offersError,
  } = useCreditCardOffersQuery({ language });
  const { data: disclaimerContent, error: policyError } = useCmsPolicyQuery({
    language,
    policy: 'creditCardsDisclaimer',
  });
  const { data: fullWidthImageData, error: brandError } = useBrandQuery({
    language,
    brandCode: 'HH',
    path: 'credit-cards',
  });

  const markdownProps = useMemo(
    () => ({
      language,
      rule: configRules?.[0],
      origin: getUrlHost(),
    }),
    [configRules, language]
  );

  const fullWidthImage = fullWidthImageData?.brand?.page?.fullWidthImage;
  const filteredItems = useMemo(
    () =>
      fullWidthImage?.reduce((acc, item) => {
        if (item?.segmentIds?.some((segmentId) => segments.includes(segmentId))) {
          acc.push({ ...item });
        }
        return acc;
      }, [] as FullWidthImageData),
    [fullWidthImage, segments]
  );

  if (offersError) {
    logWarning(
      'CREDIT_CARDS',
      generateError(offersError),
      `Error on ${useCreditCardOffersQuery.name}`
    );
  }
  if (policyError) {
    logError('CREDIT_CARDS', generateError(policyError), `Error on ${useCmsPolicyQuery.name}`);
  }
  if (brandError) {
    logWarning('CREDIT_CARDS', generateError(brandError), `Error on ${useBrandQuery.name}`);
  }

  const imageHeadlinerFmt =
    filteredItems && filteredItems?.length > 0 ? filteredItems?.[0] : fullWidthImage?.[0];

  const imageUrl = imageHeadlinerFmt?.imageCompound?.image?.variants?.find(
    (v) => v.size === 'md'
  )?.url;

  const internationalCreditCards = creditCardOffersData?.creditCardOffers?.filter(
    (card) => card?.cardType === 'international'
  )[0];

  const otherCreditCards = creditCardOffersData?.creditCardOffers?.filter(
    (card) => card?.cardType !== 'international'
  ) as OffersCreditCardGroup[];

  if (isLoading) {
    return <Spinner size="xl" />;
  }

  const renderCreditCardsLayout = () => {
    switch (language) {
      case 'ja':
        return <CreditCardsJa creditCards={otherCreditCards} />;
      default:
        return <CreditCardsEn creditCards={otherCreditCards} />;
    }
  };

  return (
    <>
      <Head
        title={t('pageTitle')}
        keywords={t('metaData.keywords')}
        description={t('metaData.description')}
      />
      <div className={getThemeClassName({ brandCode: 'CB' })}>
        <section
          className="bg-cover bg-center"
          style={{
            backgroundImage: `url("${imageUrl}")`,
          }}
        >
          <div className="flex h-[400px] w-full flex-col backdrop-brightness-75 sm:h-[700px]">
            <div
              className={cx('bg-bg-inverse text-text-inverse w-full', {
                'order-last mt-auto lg:mt-0 lg:order-none ': language !== 'ja',
              })}
            >
              <p className="container py-1">{imageHeadlinerFmt?.caption}</p>
            </div>
            <div className="container mx-auto max-w-screen-xl p-4 pt-10 sm:mx-auto">
              <p className="bg-bg text-primary border-primary my-4 max-w-fit shrink-0 rounded-full border-2 px-4 py-1 font-extrabold drop-shadow-md">
                {imageHeadlinerFmt?.attributes?.[0] ? (
                  language === 'ja' ? (
                    <span>
                      {imageHeadlinerFmt?.attributes?.[0] === 'limitedTimeOffer'
                        ? tJaCards('limitedTimeOffer_noTx')
                        : tJaCards('welcomeOffer_noTx')}
                    </span>
                  ) : (
                    <Trans t={t}>{imageHeadlinerFmt?.attributes?.[0]}</Trans>
                  )
                ) : null}
              </p>
              <div className="max-w-max">
                <h1 className="text-text-inverse text-2xl font-bold drop-shadow-md sm:text-5xl">
                  {imageHeadlinerFmt?.headline}
                </h1>
                <p className="text-text-inverse font-headline text-xl font-bold drop-shadow-md sm:mt-4 sm:text-4xl">
                  {imageHeadlinerFmt?.shortDescription}
                  <span
                    className={cx('block font-sans text-base', {
                      'text-end md:-mt-4': !fullWidthImage?.[0]?.shortDescription,
                      'ms-4 inline-block font-normal': fullWidthImage?.[0]?.shortDescription,
                    })}
                  >
                    {imageHeadlinerFmt?.description}
                  </span>
                </p>
                <Link
                  url={imageHeadlinerFmt?.link?.url as string}
                  className="btn text-text-inverse border-text-inverse bg-primary mt-8 border-2 py-2 font-extrabold"
                  underline={false}
                  cidParams={{
                    campaignId: imageHeadlinerFmt?.link?.campaignId ?? '',
                    componentName: 'CreditCards',
                  }}
                >
                  {imageHeadlinerFmt?.link?.label}
                </Link>
              </div>
            </div>
          </div>
        </section>
        {renderCreditCardsLayout()}
        <section className="container">
          <h2 className="font-headline text-hilton mb-8 mt-16 text-center text-2xl font-bold tracking-wide sm:text-left rtl:sm:text-right">
            {disclaimerContent?.policy?.content?.[0]?.heading ? (
              <Markdown {...markdownProps}>
                {disclaimerContent?.policy?.content?.[0]?.heading}
              </Markdown>
            ) : null}
          </h2>
          {disclaimerContent?.policy?.content?.map(({ description: disclaimerContent, _id }) =>
            disclaimerContent ? (
              <p className="mb-6 last:mb-32" key={`disclaimer-${_id}`}>
                {disclaimerContent}
              </p>
            ) : null
          )}
          {internationalCreditCards && internationalCreditCards?.items?.length > 0 ? (
            <InternationalCards
              internationalCardsContent={internationalCreditCards as OffersCreditCardGroup}
            />
          ) : null}
        </section>
      </div>
    </>
  );
};

export const getServerSideProps = (async (context) => {
  const { req, res, locale } = context;
  const authClient = auth(req as unknown as Request);
  const queryClient = makeServerQueryClient({
    ...REACT_QUERY_CONFIG,
    serverResponse: res,
    authClient,
  });

  const show404 = await show404NonTransPage({ url: req.path, language: locale, queryClient });

  if (show404) return { notFound: true };

  await Promise.allSettled([
    serverSideCreditCardOffersQuery(queryClient, { language: locale }),
    serverSideCmsPolicyQuery(queryClient, { language: locale, policy: 'creditCardsDisclaimer' }),
    serverSideBrandQuery(queryClient, {
      language: locale,
      brandCode: 'HH',
      path: 'credit-cards',
    }),
  ]);

  return serverSideProps({
    authClient,
    context,
    queryClient,
    pageProps: {
      metrics: {
        trackingData: {
          pageView: 'honorsPageView',
          pageData: {
            pageName: 'Credit Cards',
            expType: 'New Hilton',
            subSection: 'Brand',
            subSubSection: '',
            pageType: 'creditCards',
          },
        },
      },
    },
    namespaces: [...GLOBAL_RESOURCE_BUNDLES, 'credit-cards', 'credit-cards-ja'],
    includeConfigRules: true,
  });
}) satisfies GetServerSideProps;

export default CreditCards;
