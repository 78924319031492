import { useTranslation } from 'next-i18next';
import { useHasMounted } from '../../hooks';
import { CardBreakdown, CardComparison, CardDisplay } from '../../components/CreditCardsV2';
import type { OffersCreditCardGroup } from '@dx-ui/queries-dx-guests-ui';

export const CreditCardsEn = ({ creditCards }: { creditCards: OffersCreditCardGroup[] }) => {
  const [t] = useTranslation('credit-cards');
  const hasMounted = useHasMounted();

  return (
    <>
      <section className="hidden lg:block">
        <div className="flex w-full flex-col">
          <div className="from-secondary-alt to-secondary-alt mx-auto -mb-64 max-w-screen-xl -translate-y-1/2 bg-gradient-to-b from-0% to-100% p-8 xl:-mb-60">
            <h2 className="text-secondary text-2xl font-extrabold">{t('cardDisplayTitle')}</h2>
            <CardDisplay creditCards={creditCards} />
          </div>
        </div>
      </section>

      {/* Mobile & Tablet View */}
      <div className="from-secondary-alt to-secondary-alt mx-auto block max-w-screen-xl bg-gradient-to-b from-0% to-100% p-4 lg:hidden">
        <h2 className="text-secondary text-2xl font-extrabold">{t('cardDisplayTitle')}</h2>
        <CardDisplay creditCards={creditCards} />
      </div>

      <section className="container">
        <h2 className="font-headline text-hilton my-4 text-center text-3xl font-bold sm:text-start">
          {t('headline')}
        </h2>
        <p className="mb-6 text-base">{t('description')}</p>
        <CardBreakdown creditCards={creditCards} />
        {hasMounted ? <CardComparison creditCards={creditCards} /> : null}
      </section>
    </>
  );
};
