import { TabButton, TabList, TabPanel, TabPanels, Tabs } from '@dx-ui/osc-tabs';
import { useTranslation } from 'next-i18next';

import { BuiltInBenefits } from './BuiltInBenefits';
import { BusinessLabel, CardGroup, PersonalLabel } from './CardGroup/CardGroup';
import { EarningPoints } from './EarningPoints';
import { WelcomeOffer } from './WelcomeOffer';

import type { OffersCreditCardGroup } from '@dx-ui/queries-dx-guests-ui';

export const CardComparison = ({ creditCards }: { creditCards: OffersCreditCardGroup[] }) => {
  const [t] = useTranslation('credit-cards');

  const personalCards = creditCards?.filter((card) => card?.cardType === 'personal') || [];
  const businessCards = creditCards?.filter((card) => card?.cardType === 'business') || [];

  return (
    <>
      <h2 className="font-headline text-hilton my-8 text-center text-2xl font-bold tracking-wide sm:mt-16 sm:text-left rtl:text-right">
        {t('compareCreditCards')}
      </h2>
      <Tabs defaultActive="welcomeOfferTab" changeWithArrow={true} className="hidden lg:block">
        <TabList className="hidden h-16 w-full space-x-16 !overflow-hidden px-6 lg:block">
          <TabButton
            activeClassName="!border-b-hilton font-bold"
            className="h-12 cursor-pointer border-b-4 border-b-transparent py-1 text-xl"
            id="welcomeOfferTab"
          >
            {t('welcomeOfferTab')}
          </TabButton>
          <TabButton
            activeClassName="!border-b-hilton font-bold"
            className="h-12 cursor-pointer border-b-4 border-b-transparent py-1 text-xl"
            id="earningPointsTab"
          >
            {t('earningPointsTab')}
          </TabButton>
          <TabButton
            activeClassName="!border-b-hilton font-bold"
            className="h-12 cursor-pointer border-b-4 border-b-transparent py-1 text-xl"
            id="builtInBenefitsTab"
          >
            {t('builtInBenefitsTab')}
          </TabButton>
        </TabList>
        <TabPanels className="overflow-x-auto pt-8 sm:overflow-visible rtl:mr-5">
          <TabPanel className="grid grid-cols-12 gap-7" id="welcomeOfferTab">
            <CardGroup className="col-span-9">
              <PersonalLabel />
              <div className="grid grid-cols-3">
                <WelcomeOffer cardType="personal" cards={personalCards} />
              </div>
            </CardGroup>
            <CardGroup className="col-span-3">
              <BusinessLabel />
              <WelcomeOffer cardType="business" cards={businessCards} />
            </CardGroup>
          </TabPanel>
          <TabPanel className="grid grid-cols-12 gap-7" id="earningPointsTab">
            <CardGroup className="col-span-9">
              <PersonalLabel />
              <div className="grid grid-cols-3">
                <EarningPoints cardType="personal" cards={personalCards} />
              </div>
            </CardGroup>
            <CardGroup className="col-span-3">
              <BusinessLabel />
              <EarningPoints cardType="business" cards={businessCards} />
            </CardGroup>
          </TabPanel>
          <TabPanel className="grid grid-cols-12 gap-7" id="builtInBenefitsTab">
            <CardGroup className="col-span-9">
              <PersonalLabel />
              <div className="grid grid-cols-3">
                <BuiltInBenefits cardType="personal" cards={personalCards} />
              </div>
            </CardGroup>
            <CardGroup className="col-span-3">
              <BusinessLabel />
              <BuiltInBenefits cardType="business" cards={businessCards} />
            </CardGroup>
          </TabPanel>
        </TabPanels>
      </Tabs>
      {/* Mobile view */}
      <section className="lg:hidden">
        <h4 className="mt-8 text-xl font-bold">{t('welcomeOfferTab')}</h4>
        <div className="overflow-x-auto py-5 pl-5">
          <div className="grid w-max grid-cols-4 gap-7 rtl:mr-5 rtl:pr-2" id="welcomeOfferTab">
            <CardGroup className="col-span-3">
              <PersonalLabel />
              <div className="grid grid-cols-3">
                <WelcomeOffer cardType="personal" cards={personalCards} />
              </div>
            </CardGroup>
            <CardGroup className="col-span-1">
              <BusinessLabel />
              <WelcomeOffer cardType="business" cards={businessCards} />
            </CardGroup>
          </div>
        </div>
        <h4 className="mt-8 text-xl font-bold">{t('earningPointsTab')}</h4>
        <div className="overflow-x-auto py-5 pl-5">
          <div className="grid w-max grid-cols-4 gap-7 rtl:mr-5" id="earningPointsTab">
            <CardGroup className="col-span-3">
              <PersonalLabel />
              <div className="grid grid-cols-3">
                <EarningPoints cardType="personal" cards={personalCards} />
              </div>
            </CardGroup>
            <CardGroup className="col-span-1">
              <BusinessLabel />
              <EarningPoints cardType="business" cards={businessCards} />
            </CardGroup>
          </div>
        </div>
        <h4 className="mt-8 text-xl font-bold">{t('builtInBenefitsTab')}</h4>
        <div className="overflow-x-auto py-5 pl-5">
          <div className="grid w-max grid-cols-4 gap-7 rtl:mr-5" id="builtInBenefitsTab">
            <CardGroup className="col-span-3">
              <PersonalLabel />
              <div className="grid grid-cols-3">
                <BuiltInBenefits cardType="personal" cards={personalCards} />
              </div>
            </CardGroup>
            <CardGroup className="col-span-1">
              <BusinessLabel />
              <BuiltInBenefits cardType="business" cards={businessCards} />
            </CardGroup>
          </div>
        </div>
      </section>
    </>
  );
};
